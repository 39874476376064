import { generateEventsDefinition } from '@guiker/event'

import { Manager, PropRental } from '../../entity'
import { baseEvent } from '../base'

const basePropRentalEvent = {
  ...baseEvent,
  entity: 'PROP_RENTAL',
  data: null as unknown as PropRental,
}

export const PropRentalEventsDefinition = generateEventsDefinition({
  ...basePropRentalEvent,
  eventTypes: {
    DEACTIVATED: 'DEACTIVATED',
    PAYOUT_METHOD_UPDATED: {
      type: 'PAYOUT_METHOD_UPDATED',
      eventData: null as {
        payoutMethodId: string
        receiver: {
          userId: string
          firstName: string
          lastName: string
          emailAddress: string
        }
      },
    },
    OWNERSHIP_TRANSFERRED: {
      type: 'OWNERSHIP_TRANSFERRED',
      eventData: null as {
        from: {
          propertyOwnershipId: string
          propRentalId: string
        }
      },
    },
    MANAGER_ADDED: {
      type: 'MANAGER_ADDED',
      eventData: null as {
        manager: Manager
        inviter: string
      },
    },
    MANAGER_UPDATED: {
      type: 'MANAGER_UPDATED',
      eventData: null as {
        manager: Manager
      },
    },
    MANAGER_REMOVED: {
      type: 'MANAGER_REMOVED',
      eventData: null as {
        manager: Manager
      },
    },
  } as const,
})

export const Events = PropRentalEventsDefinition.events
export const EventTypes = PropRentalEventsDefinition.eventTypes
export type PropRentalEventTypes = typeof EventTypes
