import { routesBuilder } from '@guiker/api-definition'

import { PropertyOwner } from '../../../entity'
import { paths } from './paths'

const basePath = paths.propertyOwner.all.base()
const { buildGet } = routesBuilder<PropertyOwner>()({ basePath })

export const propertyOwnerRoutes = {
  readAllPropertyOwners: buildGet({
    paginated: true,
    response: null as PropertyOwner,
  }),
}
